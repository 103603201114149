import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import styled from "styled-components"

import { media } from "../styles/theme"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { urls } from "../urls"

const S = {}

S.Justified = styled.div`
  text-align: justify;
`
S.Center = styled.div`
  text-align: center;
`
S.Gap = styled.div`
  padding-top: 3rem;
`

const StudioPage = () => {
  const teamImg = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "images/kamiko_1920.png" }) {
          childImageSharp {
            gatsbyImageData(width: 1386, quality: 100, layout: CONSTRAINED)
          }
        }
      }
    `
  )

  const jsonLdDataWebsite = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: "https://kamiko.studio" + urls.STUDIO,
    name: "Studio",
  }

  const jsonLdBreadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: "https://kamiko.studio/",
        name: "Kamiko",
      },
      {
        "@type": "ListItem",
        position: 2,
        item: "https://kamiko.studio" + urls.STUDIO,
        name: "Studio",
      },
    ],
  }

  return (
    <Layout>
      <Seo
        title="Studio"
        url={urls.STUDIO}
        imageSrc={getSrc(teamImg.file.childImageSharp.gatsbyImageData)}
        jsonLdData={[jsonLdDataWebsite, jsonLdBreadcrumbs]}
      />
      <div>
        <S.Justified>
          <h1>Studio</h1>
          <p>Tworzymy piękne wnętrza.</p>
          <p>
            Kamiko to studio projektowe specjalizujące się w kompleksowej
            aranżacji przestrzeni mieszkalnych i publicznych tworzone przez
            uzupełniający się duet - Karolinę Sodkiewicz i Igę Kostrzewską.
            Projektowanie wnętrz to nasza pasja i praca od wielu lat. Zajmujemy
            się aranżacją przestrzeni zarówno z rynku deweloperskiego jak i
            wtórnego jednak szczególnym zamiłowaniem obdarzamy obiekty z
            historią - stare kamienica, obiekty poprzemysłowe.
          </p>
          <p>
            Naszą misją jest tworzenie wnętrz niepowtarzalnych, interesujących i
            spójnych stylistycznie, których ostateczny wygląd jest fuzją
            przemyślanej funkcji, dobrego smaku, oraz potrzeb i upodobań
            estetycznych klienta. Każde wnętrze postrzegamy przede wszystkim
            jako miejsce do życia, tworzone przez unikalne doświadczenie
            użytkowników oraz atmosferę, którą osiągamy poprzez zastosowanie
            odpowiednich narzędzi projektowych. Dzięki dbałości o każdy detal,
            owocem wspólnego procesu projektowego stają się piękne, ponadczasowe
            miejsca, w których żyje się komfortowo.
          </p>
          <GatsbyImage
            image={teamImg.file.childImageSharp.gatsbyImageData}
            alt="Zespół Kamiko"
          />
          <h2>Proces projektowy</h2>
          <p>
            Przebieg procesu projektowego dostosowujemy do potrzeb inwestora
            oraz inwestycji. Przeprowadzamy go w zaplanowanym na samym początku
            współpracy porządku, dzięki czemu prace postępują etapowo i
            sprawnie. Koncept procesu projektowego jest dla nas tak samo istotny
            jak sam koncept przestrzeni, którą projektujemy.
          </p>
          <p>
            <b>ETAP I: Konsultacje</b>
            <br />
            Rozmowy o oczekiwaniach Klienta wobec projektu oraz preferencjach
            stylistycznych. Na tym etapie tworzymy propozycje możliwych we
            wnętrzu rozwiązań. Efektem konsultacji jest diagnoza potrzeb i
            priorytetów Klienta stanowiąca punkt wyjścia do wspólnych, kolejnych
            działań.
          </p>
          <p>
            <b>ETAP II: Koncepcja funkcjonalna</b>
            <br />
            Koncept najwygodniejszej, najbardziej ergonomicznej wersji
            przestrzeni, który przedstawiany w formie rzutu. Na tym etapie
            planujemy funkcję pomieszczeń i proponujemy rozwiązania podziału
            przestrzeni mieszkalnej czy biurowej na strefy.
          </p>
          <p>
            <b>ETAP III: Koncepcja plastyczna</b>
            <br />
            Całościowy, spójny koncept wnętrza, który przedstawiany w formie
            kolaży i wizualizacji. Na tym etapie tworzymy charakter wnętrza -
            następuje wybór form, kolorów, tkanin, mebli i dodatków.
          </p>
          <p>
            <b>ETAP IV: Książka projektowa</b>
            <br />
            Na tym etapie podsumowujemy wybrane rozwiązania i tworzymy projekt
            wykonawczy przeznaczony dla dostawców i wykonawców, w którego skład
            najczęściej wchodzą: zmiany instalacyjne, rozrysy ścian łazienek,
            kuchni i innych pomieszczeń, projekty indywidualnych elementów pod
            zabudowę.
          </p>
          <p>
            <b>ETAP V: Zestawienie materiałów oraz kosztorys</b>
            <br />W naszych projektach bardzo istotny jest budżet klienta,
            którego ramy uwzględniamy w naszych propozycjach z poprzednich
            etapów. Budżet przedstawiamy w tabeli, w której wymieniamy listę
            proponowanych materiałów i wyposażenia wnętrza. Tabela służy
            kontrolowaniu wydatków a także wskazuje wszystkie potrzebne ilości
            materiałów do realizacji.
          </p>
          <p>
            <b>ETAP VI: Realizacja</b>
            <br />
            Na życzenie Klienta prowadzimy nadzory autorskie nad realizacją
            projektów. Zakres prac oraz ich wycena zostaje każdorazowo ustalona
            w zależności od potrzeb Zamawiającego oraz rodzaju inwestycji.
          </p>
        </S.Justified>
        <S.Gap />
        <S.Center>
          <p>Zapraszamy do współpracy!</p>
        </S.Center>
      </div>
    </Layout>
  )
}

export default StudioPage
